<script setup lang="ts">
const sliders = [
  {
    name: 'Playermon',
    image: 'https://playermon.com/_nuxt/playermon_horizontal_logo.e6a11043.png',
    url: '/download',
    text: "Experience Anchorland's adventure saga! PvP battles with your NFT Playermons for glory. Decorate SpaceDen, earn SGEMs, climb the leaderboard!",
    embedVideo: 'https://www.youtube.com/embed/6dhZ8DZC2lA'
  },
  {
    name: 'Playermon Planet',
    image: 'https://planet.playermon.com/img/home/planet-invasion-text.png',
    url: 'https://planet.playermon.com/',
    text: 'Embark on an epic journey, unite in the battle for planetary dominance, own your planet, and build your own noble houses.',
    embedVideo: 'https://www.youtube.com/embed/0BNsHR9gG8E'
  },
  {
    name: 'Playermon Empire',
    image: '/img/home/empire-expedition-title.png',
    url: 'https://playermon.com/empire',
    text: 'Unlock BTC rewards and earning limitless USDT worth of income with our referral game. Join now and assemble your Playermon army!',
    embedVideo: 'https://www.youtube.com/embed/7I-7NSoiwZM'
  }
]

const activeSliderName = ref('Playermon Empire')

const setActiveSlider = (sliderName: string) => {
  activeSliderName.value = sliderName
}

const filteredSliders = computed(() => {
  return sliders.filter(slider => slider.name === activeSliderName.value)
})
</script>

<template>
  <div class="container p-10 mx-auto">
    <div class="flex flex-col mb-8">
      <div class="justify-center">
        <h1 class="text-4xl font-bold text-center uppercase text-smoke-white">
          Discover the Playermon Universe
        </h1>
      </div>

      <div v-for="(slider, index) in filteredSliders" :key="index">
        <div class="flex flex-col-reverse lg:flex-row md:px-5">
          <div class="flex flex-col justify-center w-full p-8 lg:w-1/2">
            <div class="flex flex-col items-center gap-2 lg:items-start">
              <img :src="slider.image" class="w-full h-auto lg:w-full md:w-1/2">
              <p class="mb-2 text-center text-white lg:text-left">
                {{ slider.text }}
              </p>
              <a :href="slider.url" class="rounded-3xl bg-gradient-to-r from-[#4FA9F9] to-[#161A69] mt-2 py-3 px-5">
                <button>
                  <p v-if="slider.name !== 'Playermon Empire'" class="font-bold text-center text-white uppercase">
                    Play For Free
                  </p>
                  <p v-if="slider.name === 'Playermon Empire'" class="font-bold text-center text-white uppercase">
                    Go To Page
                  </p>
                </button>
              </a>
            </div>
          </div>

          <div class="flex items-center justify-center w-full p-8">
            <iframe
              class="hidden rounded-lg lg:block"
              width="690"
              height="390"
              :src="slider.embedVideo + '?autoplay=0'"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
            <iframe
              class="hidden lg:hidden md:block"
              width="560"
              height="315"
              :src="slider.embedVideo + '?autoplay=0'"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
            <iframe
              class="block md:hidden"
              height="170"
              :src="slider.embedVideo + '?autoplay=0'"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </div>
      </div>

      <div class="hidden grid-cols-3 md:grid">
        <div
          v-for="(slider, index) in sliders"
          :key="index"
          class="flex items-center justify-center lg:mx-4 mx-2 bg-[#080B50] hover:bg-[#161A69] hover:border-[#57D3FD] hover:border-2 transition ease-in-out delay-50 rounded-lg"
          :class="{'border-2 border-[#57D3FD]' : slider.name === activeSliderName}"
          @click="setActiveSlider(slider.name)"
        >
          <button class="w-1/2">
            <img :src="slider.image" class="w-full h-auto">
          </button>
        </div>
      </div>

      <div class="flex flex-col gap-2 md:hidden">
        <div
          v-for="(slider, index) in sliders"
          :key="index"
          class="flex items-center justify-center lg:mx-4 mx-2 bg-[#080B50] hover:bg-[#161A69] hover:border-[#57D3FD] hover:border-2 transition ease-in-out delay-50 rounded-lg h-24"
          @click="setActiveSlider(slider.name)"
        >
          <button class="w-1/2">
            <img :src="slider.image" class="w-full h-auto">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
